<template>
  <div class="appointSuccess">
    <div class="appointSuccess-t">
      <img src="./img/success.png" alt="" />
    </div>
    <div class="appointSuccess-c">你的预定订单已提交成功</div>
    <div class="appointSuccess-b">
      <div class="btn-l" @click="toAppointIndex">继续预定</div>
      <div class="btn-l" @click="toLook">去查看</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "areaSession",
  data() {
    return {};
  },
  created() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    defOrgId() {
      return this.$store.state.defOrgId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  mounted() {},
  methods: {
    toLook() {
      this.$router.replace({
        name: "appointList",
      });
    },
    toAppointIndex() {
      this.$router.replace({
        name: "areaAppoint",
        query: {
          userId: this.userId,
          defOrgId: this.defOrgId,
          houseId: this.houseId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.appointSuccess {
  background: #f7f7f7;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;
  .appointSuccess-t {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .appointSuccess-c {
    text-align: center;
    margin: 40px 0 74px;
    font-size: 32px;
    font-weight: 400;
    color: #323334;
    line-height: 44px;
  }
  .appointSuccess-b {
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
  }
  .btn-l,
  .btn-r {
    text-align: center;
    line-height: 80px;
    width: 328px;
    height: 80px;
    border-radius: 48px;
    font-size: 32px;
    font-weight: 400;
    color: #007eff;
    border: 2px solid #007eff;
  }
}
</style>
