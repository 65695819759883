import { render, staticRenderFns } from "./appointSuccess.vue?vue&type=template&id=bae56848&scoped=true"
import script from "./appointSuccess.vue?vue&type=script&lang=js"
export * from "./appointSuccess.vue?vue&type=script&lang=js"
import style0 from "./appointSuccess.vue?vue&type=style&index=0&id=bae56848&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bae56848",
  null
  
)

export default component.exports